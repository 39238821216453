import type { AxiosInstance } from 'axios'
import { subYears, format } from 'date-fns'
import { LITHIUM } from '~/constants/urls'
import type { PriceIndexSummary, PriceSummaryValue } from '~/types/domain/priceIndexSummary'
import type { PricesResponse } from '~/types/dto/pricesResponse'
import type {
  PriceCategoryResponse,
  PricesSummaryResponse,
} from '~/types/dto/pricesSummaryResponse'
import type { PriceForecastsResponse } from '~/types/dto/priceForecastsResponse'
import type { PriceForecastScenario } from '~/types/domain/priceForecastScenario'
import { DateFormats } from '~/constants/general'
import type { PriceSummary } from '~/types/domain/priceSummary'
import type { EquityDataResponse } from '~/types/dto/equityDataResponse'
import type { PricesRangeResponse } from '~/types/dto/pricesRangeResponse'
import type { PricesIndexResponse } from '~/types/dto/pricesIndexResponse'

export const prices = (http: AxiosInstance) => ({
  getEquityData: () => http.get<EquityDataResponse>(LITHIUM.EQUITY_DATA),
  getPriceIndex: async (): Promise<any> => {
    const response = await http.post<PricesIndexResponse>(LITHIUM.PRICE_INDEX_V2)
    return response
  },
  getPriceRange: async (): Promise<any> => {
    const response = await http.get<PricesRangeResponse>(LITHIUM.PRICES_RANGE_V2)
    return response
  },
  getDownloadUrlMethodology: () => http.get(LITHIUM.PRICES_DOWNLOAD_METHODOLOGY),
  getDownloadUrlForecasts: () => http.get(LITHIUM.PRICES_DOWNLOAD_FORECASTS),
  getSummaryByCategories: async (): Promise<PriceIndexSummary[]> => {
    const currentDate = new Date()
    const startDate = format(subYears(currentDate, 1), DateFormats.yyyy_MM_dd)
    const endDate = format(currentDate, DateFormats.yyyy_MM_dd)

    const [summaryResponse, pricesResponse] = await Promise.all([
      http.post<PricesSummaryResponse>(LITHIUM.PRICES_SUMMARY_V2),
      http.post<PricesResponse>(LITHIUM.PRICES_V2, {
        from: startDate,
        to: endDate,
      }),
    ])

    return summaryResponse.data.map(
      ({ frequency, name, series }: PriceCategoryResponse): PriceIndexSummary => ({
        values: series.map(
          ({ data, grade, purity, shippingRoute }): PriceSummaryValue => ({
            name: `${shippingRoute.name};${purity.name}${grade ? ';' + grade : ''}`,
            today: data.today,
            last: data.last,
            latestChange: data.latestChange?.value,
            yearOnYear: data.yearOnYear?.value,
            yearToDate: data.yearToDate?.value,
            series: pricesResponse.data
              .reduce((accum, current) => {
                const { category, shippingRoute: serieShippingRoute } = current

                const validation =
                  category.name === name.replace(/ \(Spot\)$/, '') &&
                  serieShippingRoute.name === shippingRoute.name

                if (!validation) return accum

                return current
              })
              .series?.map(({ date, valueMid }) => ({ date, price: valueMid }))
              .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()),
          }),
        ),
        label: name,
        frequency,
      }),
    )
  },
  getPriceSummary: async (): Promise<PriceSummary[]> => {
    const { data } = await http.post<PricesSummaryResponse>(LITHIUM.PRICES_SUMMARY_V2)
    return data as PriceSummary[]
  },
  getPriceForecastsByType: async (
    type,
  ): Promise<{
    scenarios: PriceForecastScenario[]
    sourceData: {
      year: number
      quarter: number
    }
  }> => {
    const { data: response, $metadata } = await http.get<PriceForecastsResponse>(
      LITHIUM.PRICE_FORECASTS,
      {
        params: { priceType: type },
      },
    )
    const scenarios: PriceForecastScenario[] = []

    const getPeriodsByScenario = (scenario) => {
      return scenario.periods
        .sort((a, b) => a.order - b.order)
        .map((periodItem) => {
          const name = periodItem.period
          const region = periodItem.region
          const incoterm = periodItem.incoterm
          const years = periodItem.years
            .filter((yearItem) => yearItem.value)
            .map((yearItem) => ({
              year: yearItem.year,
              values: yearItem.value,
            }))
          const quarters = periodItem.years
            .filter((yearItem) => yearItem.quarters?.length > 0)
            .map((yearItem) => ({
              year: yearItem.year,
              values: yearItem.quarters,
            }))
          return { name, years, quarters, region, incoterm }
        })
    }

    response.forEach((item) => {
      item.scenarios.forEach((scenario) => {
        const currentScenarioIndex = scenarios.findIndex(
          (scenarioItem) => scenarioItem.name === scenario.scenario,
        )

        if (currentScenarioIndex === -1) {
          scenarios.push({
            name: scenario.scenario,
            products: [{ name: item.product, periods: getPeriodsByScenario(scenario) }],
          })
          return
        }

        const currentScenario = scenarios[currentScenarioIndex]
        const currentProductIndex = currentScenario.products.findIndex(
          (productItem) => productItem.name === item.product,
        )

        if (currentProductIndex === -1) {
          currentScenario.products.push({
            name: item.product,
            periods: getPeriodsByScenario(scenario),
          })
        }
      })
    })
    return { scenarios, sourceData: $metadata.sourceData }
  },
  getPriceHeaders: () => http.get(LITHIUM.PRICES_HEADERS),
})
