import { AxiosInstance } from 'axios'
import { COSTS_MODEL } from '~/constants/urls'

const noExtraData = ['totalMines', 'totalProduction']

const getCost = (item, type) => {
  if (type === 'C1') return item.c1Cost
  if (type === 'C2') return item.c2Cost
  if (type === 'C3') return item.c3Cost
}

const getCosts = (item, type) => {
  const { c1Cost, c2Cost, c3Cost } = item

  if (type === 'C1') return { c1Cost }
  if (type === 'C2') return { c1Cost, c2Cost }
  if (type === 'C3') return { c1Cost, c2Cost, c3Cost }
}

export const costsModel = (http: AxiosInstance) => ({
  async getCostsModel({ product, type, group, year }) {
    const params = { productType: product, costType: type, groupBy: group, year }
    const { data: response, $metadata: meta } = await http.get(COSTS_MODEL.LITHIUM, { params })

    const data = response.map((item) => {
      const { productionLCE_TPA: production, aggregationLevel } = item
      const cost = getCost(item, type)
      const groupData = excludeData(item[item.aggregationLevel], noExtraData)
      const costs = getCosts(item, type)
      const filterBy = groupData?.oreType?.name ?? groupData?.name
      const result = { cost, filterBy, aggregationLevel, production, ...groupData, ...costs }
      return result
    })

    return { data, sourceData: meta.sourceData }
  },
})
