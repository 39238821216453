import type { AxiosInstance } from 'axios'
import { INITIAL_VALUE_TYPE, ValueType } from '~/constants/general'
import { LITHIUM } from '~/constants/urls'
import type { Country } from '~/types/domain/country'
import type { Mine } from '~/types/domain/mine'
import type { MineStatus } from '~/types/domain/mineStatus'
import type { Plant } from '~/types/domain/plant'
import type { AllCountriesResponse } from '~/types/dto/allCountriesResponse'
import type { MineStatusesResponse } from '~/types/dto/mineStatusesResponse'
import type { MinesResponse } from '~/types/dto/minesResponse'
import type { PlantsResponse } from '~/types/dto/plantsResponse'
import type { PriceChangeResponse } from '~/types/dto/priceChangeResponse'

export const general = (http: AxiosInstance) => ({
  getInfo: () => http.get(LITHIUM.INFO),
  getChemicalInfo: () => http.get(LITHIUM.CHEMICAL_INFO),
  getMinedInfo: () => http.get(LITHIUM.MINED_INFO),
  getHeaders: (valueType: ValueType = INITIAL_VALUE_TYPE) =>
    http.get(LITHIUM.GLOBAL_HEADER, {
      params: {
        valueType,
      },
    }),
  getChanges: (): Promise<PriceChangeResponse> => http.get(LITHIUM.PRICES_LITHIUM_CHANGES),
  getMines: async (
    year: number,
    coordinatesStatus: 'WITH' | 'WITHOUT' | 'ALL',
    valueType: ValueType = INITIAL_VALUE_TYPE,
  ): Promise<Mine[]> => {
    const response = await http.get<MinesResponse>(LITHIUM.ALL_MINES, {
      params: {
        year,
        coordinatesStatus,
        valueType,
      },
    })
    return response.data as Mine[]
  },
  getPlants: async (
    year: number,
    coordinatesStatus: 'WITH' | 'WITHOUT' | 'ALL',
    valueType: ValueType = INITIAL_VALUE_TYPE,
  ): Promise<Plant[]> => {
    const response = await http.get<PlantsResponse>(LITHIUM.ALL_PLANTS, {
      params: {
        year,
        coordinatesStatus,
        valueType,
      },
    })
    return response as Plant[]
  },
  getMineStatuses: async (): Promise<MineStatus[]> => {
    const response = await http.get<MineStatusesResponse>(LITHIUM.MINE_STATUS)
    return response.data.map(({ id, name }): MineStatus => ({ id, name }))
  },
  getAllCountries: async (): Promise<Country[]> => {
    const response = await http.get<AllCountriesResponse>(LITHIUM.ALL_COUNTRIES)
    return response.data.map(({ id, name, slug }): Country => ({ id, name, slug }))
  },
})
